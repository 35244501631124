.shipment-package-type,
.shipment-postage-type {
  min-height: 5rem;
}

@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .shipment-package-type,
  .shipment-postage-type {
    min-height: 7rem;
  }
}
@media only screen and (max-width: 350px) {
  /*Small smartphones [325px -> 425px]*/
  .shipment-package-type,
  .shipment-postage-type {
    min-height: 9.5rem;
  }
}
