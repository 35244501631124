.survey-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.survey-summary-results {
  min-width: 50rem;
}

@media only screen and (max-width: 600px) {
  .survey-summary-results {
    min-width: 10rem;
  }
}
@media only screen and (max-width: 425px) {
  .survey-summary-results {
    min-width: 10rem;
  }
}

.survey-summary-responses-container {
  max-height: 17rem;
  max-width: 100%;
  overflow-y: scroll;
  padding: 2rem 0;
}

.survey-summary-responses-container.allow-full-length {
  max-height: 100%;
  overflow-y: auto;
}

.survey-summary-text-item {
  border-bottom: 1px solid #ccc;
  padding: 2rem 0;
}
