.quill-custom
  .js-wysiwygeditor.survey-contentEditable
  .ql-editor.ql-blank::before {
  font-style: italic;
}

.quill-custom .js-wysiwygeditor.survey-contentEditable .ql-editor {
  min-height: 2rem;
  max-height: 60vh;
  padding: 0;
}

.quill-custom
  .js-wysiwygeditor.survey-contentEditable.survey-content-description
  .ql-editor
  > p {
  color: #8c98a4 !important;
}

.survey-content,
.survey-content :not(.block-scrollable),
.survey-contents {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.survey-content::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.survey-content-text {
  font-size: 1.5rem;
}

.survey-contentEditable {
  border: 0 !important;
}

.survey-contentEditable .ql-editor {
  text-align: center;
}

.survey-icon-box {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  padding: 0px 6px;
  -moz-box-pack: justify;
  justify-content: center;
}
.survey-icon-box.wide {
  width: 52px;
  height: 24px;
  justify-content: space-between;
}

.survey-icon-root {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: auto;
  height: auto;
  color: #131313;
}

.survey-icon-index {
  color: #131313;
  font-weight: 700;
  font-size: 0.75rem;
}

.survey-content-list-text {
  flex: 1 1 0%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -moz-box-orient: vertical;
  position: relative;
  word-break: break-word;
  margin-left: 0.75rem;
}

.survey-contents .active {
  background-color: #f0f0f0;
}

/*Image Layouts*/
.survey-content-image-layout-card {
  display: flex;
  align-items: center;
  box-sizing: inherit;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgb(115, 115, 115);
  color: rgb(38, 38, 39);
  -moz-osx-font-smoothing: grayscale;
}

.survey-content-image-layout-card:hover,
.survey-content-image-layout-card.selected {
  border: 1px solid rgb(4, 135, 175);
}

.survey-content-image-layout-card:hover .image-layout-line,
.survey-content-image-layout-card:hover .image-layout-box,
.survey-content-image-layout-card.selected .image-layout-line,
.survey-content-image-layout-card.selected .image-layout-box {
  background-color: rgb(4, 135, 175);
}

.image-layout-line {
  transform: scale(1, 0.7);
  height: 2px;
  border-bottom-right-radius: 2px;
  background-color: rgb(115, 115, 115);
}

.image-layout-box {
  border-bottom-right-radius: 2px;
  background-color: rgb(115, 115, 115);
  -moz-box-align: center;
  align-items: center;
  display: flex;
}

.survey-content-image-wrapper.alignment-middle {
  max-width: 15rem;
  max-height: 15rem;
}

.survey-content-image-wrapper.alignment-left-or-right {
  max-width: 100%;
  max-height: 100%;
}

img.survey-content-image {
  max-height: 100%;
  max-width: 100%;
}

.survey-content-multichoice-option input {
  cursor: pointer;
}

.survey-content-multichoice-option .input-group-append {
  opacity: 0;
}

.survey-content-multichoice-option:hover .input-group-append {
  opacity: 1;
  cursor: pointer;
}

.survey-content-item .dropleft {
  opacity: 0;
}

.survey-content-item:hover .dropleft {
  opacity: 1;
}

.survey-picture-choice,
.survey-picture-add-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 7.5rem;
  min-height: 10rem;
  border: 0.0625rem solid #377dff;
  cursor: pointer;
  margin-bottom: 0;
}

.survey-picture-choice.no-choice-image {
  font-size: 2rem;
  color: #377dff;
}

.survey-picture-choice:hover .survey-picture-choice-cpd,
.survey-picture-choice:hover .survey-picture-choice-remove {
  display: flex;
}

.survey-picture-choice img {
  object-fit: contain;
}

.survey-picture-choice.show-label {
  border-radius: 0.3125rem 0.3125rem 0 0;
}

.survey-picture-choice.locked img {
  -webkit-filter: blur(0.3125rem); /* Safari 6.0 - 9.0 */
  filter: blur(0.3125rem);
}

.survey-picture-choice-cpd {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0.3rem;
  left: 0.3rem;
  max-width: 5rem;
  z-index: 1;
  display: none;
}

.survey-picture-choice-cpd-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  outline: currentcolor none medium;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  padding: 0px;
  margin-right: 0.3rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgb(227, 227, 227);
}

.survey-picture-choice-remove {
  position: absolute;
  display: none;
  top: 0.1rem;
  right: 0.1rem;
  width: 1.375rem;
  height: 1.375rem;
  color: #fff;
  background: rgba(38, 38, 39, 0.75) none repeat scroll 0% 0%;
  border-radius: 50%;
  padding: 0px;
  -moz-box-align: center;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 1.25rem;
  line-height: 1rem;
}

.survey-picture-add-choice {
  border: 0.0625rem dashed #377dff;
  font-size: 2rem;
  color: #377dff;
}

.survey-picture-choice-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.0625rem solid #377dff;
  border-top: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
  font-size: 1rem;
  max-width: 7.875rem;
}
