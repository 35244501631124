/*Formatting*/
.avatar-img {
  width: 100%;
}

/*Hide Arrows in number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*Element Level Styles*/
select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

/*Mobile Specific*/
@media only screen and (max-width: 320px) {
  .iphone-5-cart {
    margin-left: 17rem;
    margin-top: -2.6rem;
  }
}

.mobile-cbb-icon {
  font-size: 1.4rem;
}

/*Homepage*/
.image-with-description {
  position: relative;
}

.image-with-description img {
  border-radius: 10px;
}

.image-with-description .common-list-items {
  padding-top: 1rem;
}

.image-with-description-shape-bg {
  right: -8%;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 20%;
  z-index: 0;
}

/* .image-with-description-block {
	padding: 6.5rem 0 6.5rem;
} */

/*Navbar*/
.navbar-wrapper {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}

/*Links*/
.styled-underlined-link {
  cursor: pointer;
  color: #1f1f1f;
  border-bottom: 2px solid #fc90bd;
  width: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
}

.styled-underlined-link:hover {
  color: #1f1f1f;
  border-bottom: 2px solid #fc4e96;
}

/*Component Specific*/

/*Address Book*/

.list-address-book-item.active {
  background-color: #fc90bd;
}

/*Miscellaneous*/

/*Centering*/
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
}

/*Clicking*/
.clickable {
  cursor: pointer !important;
}

/*Additional Border Styling*/
.dark-border-wrapper {
  border: 1px solid #1f1f1f;
}

/* Hide dropdown carets */

.caret-off::before {
  display: none;
}
.caret-off::after {
  display: none;
}
